<template>
  <div class="fs-14 full-height flex column">
    <div class="sidebar__section px-3">
      <p class="sidebar__analytics-label">
        TX hash
      </p>
      <p
        class="sidebar__analytics-value flex align-center"
        :title="selectedElement.value"
      >
        <span class="m-word-break-all">
          {{ selectedElement.data.txHash }}
        </span>
        <gl-menu-item
          class="sidebar__history-copy"
          icon="copy"
          :icon-height="24"
          :icon-width="24"
          @click="copy(selectedElement.data.txHash)"
        />
      </p>
    </div>
    <div class="analytics-tabs mb-4">
      <div
        v-for="(tab, index) in tabs"
        :key="index"
      >
        <div
          v-if="tab.visible"
          class="analytics-tabs-item analytics-tabs-item--full"
          :class="[{'analytics-tabs-item--active': activeTab === tab.name}]"
          @click="changeTab(tab.name)"
        >
          <div>
            {{ tab.title }}
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="activeTab === 'sof'"
      class="pt-0 relative flex-grow-1"
    >
      <div v-if="calcLoading">
        <gl-loader class="table__loader" />
      </div>
      <div v-else>
        <div class="px-3 mt-3 flex space-between align-flex-end">
          <div class="column">
            <div class="flex space-between mb-2 align-center">
              <div class="fs-12 mb-1 grey-text-6-e uppercase bold">
                filter by token
              </div>
            </div>
            <vSelect
              v-model="token"
              class="tokens-list mr-1"
              :clearable="false"
              :disabled="isTxDataLoading"
              label="symbol"
              :options="tokensList"
              placeholder="Select Token"
              @input="handleSelectToken"
            >
              <template v-slot:selected-option="{ symbol, icon }">
                <GlImg
                  v-if="icon"
                  class="mr-1"
                  :src-val="icon"
                />
                {{ symbol }}
              </template>
              <template v-slot:option="option">
                <GlImg
                  v-if="option.icon"
                  class="mr-1"
                  :src-val="option.icon"
                />
                {{ option.symbol }}
              </template>
            </vSelect>
          </div>
          <div
            v-if="appConfig.VUE_APP_SCORE_CHART_DONUT"
            class="flex fs-14 align-end mr-3"
          >
            Amount
            <div class="px-1 switch__wrap">
              <input
                id="switch"
                v-model="trackActivator"
                type="checkbox"
                @click="trackBy()"
              >
              <label for="switch">Toggle</label>
            </div>
            %
          </div>
        </div>
        <div
          v-if="!uofData.incomingData.typeGrouping.length"
          class="empty-users-data text-center"
        >
          Data is empty
        </div>
        <template v-if="appConfig.VUE_APP_SCORE_CHART_DONUT">
          <div
            v-if="uofData.incomingData.typeGrouping.length > 0"
            class="flex align-center justify-center relative"
          >
            <gl-pie-chart
              v-if="!isMobile && !calcLoading"
              ref="pie"
              class="relative"
              :data-source="uofData.incomingData.typeGrouping"
              :height="470"
              :width="'100%'"
              @mouseout="sectionData = null"
              @move="handlePieHover"
            >
              <template slot="tooltip">
                <transition name="fade">
                  <div
                    v-if="sectionData"
                    id="sourcePie"
                    class="testPie"
                  >
                    <div
                      v-for="(owner, index) in sectionData.owners"
                      :key="index"
                      :class="{'mb-2': index < sectionData.owners.length - 1}"
                    >
                      <div class="flex align-center bold">
                        <div class="sub-types-amount">
                          <span class="">
                            {{ trackByField === 'share'
                              ? formatShare(allDataSource.length === 1 && sectionData.owners.length === 1 ? 1 : owner[trackByField])
                              : owner.formattedAmount }}
                          </span>
                        </div>
                        <div
                          v-popover:tooltip.top="`${capitalizeFirstLetter(owner.name)}`"
                          class="ml-3 ellipsis sub-types-owner max-w-300"
                        >
                          {{ capitalizeFirstLetter(owner.name) }}
                        </div>
                      </div>
                      <div
                        v-if="trackByField === 'amount'"
                        class="price fs-13"
                      >
                        {{ formatByPrice(owner['amountCur']) }}
                      </div>
                    </div>
                  </div>
                </transition>
              </template>
            </gl-pie-chart>
            <PieDataList
              v-if="isMobile"
              class="m-fullwidth px-3"
              :data="allDataSource"
              :legend="null"
              title=""
              track-by-label="funds.type"
              track-by-label-support="funds.name"
              :track-value-by-field="trackByField"
            />
            <div class="risk-score-wrap">
              <strong class="risk-score-label">
                Risk Score
              </strong>
              <div
                class="risk-score-value"
                :style="`color: ${findColorByTypeScore(totalFunds)}`"
              >
                {{ formatFunds(totalFunds, false) }}
              </div>
            </div>
          </div>
        </template>
        <div
          v-else-if="uofData.incomingData.typeGrouping.length || uofData.outgoingData.typeGrouping.length"
          class="px-3"
        >
          <div class="flex flex-wrap space-between mt-3">
            <GlSelectButton
              :class="[{'mb-1': isMobile}]"
              :disabled="calcLoading"
              :options="partBarOptions"
              :value="activePartBar"
              @input="changeActivePartBarValue"
            />
            <GlSelectButton
              v-if="Number(appConfig.VUE_APP_SCORE_REQUEST_DIRECTION) === 2"
              :class="[{'mb-1': isMobile}]"
              :disabled="calcLoading"
              :options="exposurePartOptions"
              :value="activeExposurePart"
              @input="changeActiveExposure"
            />
          </div>
          <div>
            <div class="pt-2">
              <div class="chart-wrap__side-panel">
                <gl-scoring-bar-chart
                  :active-part-bar="activePartBar"
                  :basis-percent-graph-width="30"
                  class="transparent-space-right"
                  :data-table="activeBarData"
                  full
                  :loading="calcLoading"
                  :max-share="uofData[`${activeExposurePart.value}Data`].maxShare"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          v-else
          class="empty-users-data text-center"
        >
          Data is empty
        </div>
      </div>
      <div class="source__action-block mt-2">
        <div v-if="!calcLoading">
          <div
            v-if="addressRiskPercent > 0 || addressAreUnidentified"
            class="flex align-center mb-1"
          >
            <p class="sidebar__analytics-label__simple mr-2">
              AML RISK DETECTED
            </p>
            <gl-menu-item
              icon="risk-mark"
              :icon-height="16"
              :icon-width="16"
              :label="showRiskMsg ? 'Hide' : 'Show'"
              @click="showRiskMsg = !showRiskMsg"
            />
          </div>
          <div v-if="showRiskMsg">
            <StatusBlock
              v-if="addressRiskPercent > 0"
              class="mb-2"
              :label="txRiskPercentLabel"
            />
            <StatusBlock
              v-if="addressAreUnidentified"
              class="mb-2"
              label="More than 75% of sources for this transaction are unidentified"
            />
          </div>
          <div class="gl-form__actions">
            <button
              class="gl-button gl-button--dark full-submit gl-form__button gl-button--padder"
              @click="toFullReport(selectedElement.data.txHash)"
            >
              get full report
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="activeTab === 'general'">
      <div class="stat px-3">
        <div class="stat-tag full">
          <InfoBlock
            v-if="selectedElement.data.additionalData.method_name"
            class="mr-2"
            label="Method"
            :value="selectedElement.data.additionalData.method_name"
          />
          <InfoBlock
            v-if="selectedElement.data.amount"
            class="mr-2"
            label="Total amount"
            :rate="formatByPrice(selectedElement.data.price)"
            rate-time="historical"
            :rate-timestamp="selectedElement.data.additionalData.valuePriceTimestamp"
            :value="selectedElement.formattedTotalAmount"
          />
          <InfoBlock
            label="Timestamp"
            :value="formatDate(selectedElement.data.additionalData.timestamp * 1000, 'dd.MM.yyyy HH:mm')"
          />
          <InfoBlock
            label="Block"
            :value="selectedElement.data.additionalData.blockHeight"
          />
        </div>
      </div>
      <div class="px-3 mb-4">
        <InfoBlock
          class="mb-4"
          full
          label="From"
          :value="selectedElement.data.sourceAddress"
        />
        <InfoBlock
          full
          label="To"
          :value="selectedElement.data.targetAddress"
        />
      </div>
      <div
        v-if="cytoscape.hasSimpleViewElement(selectedElement.data.txHash)"
        class="px-3 mb-4"
      >
        <!-- <gl-checkbox
          v-model="simpleView"
          class="mb-2"
          label="Show internal transfers "
          @input="handleSimpleView"
        /> -->
        <gl-checkbox
          v-model="advancedView"
          label="Show smart-contract calls"
          @input="handleFullView"
        />
      </div>
      <div v-if="txsList.length !== total">
        <StatusBlock
          v-if="total > 10"
          class="mb-2 px-3"
          label="Limited number of txs is displayed"
        />
        <o-table
          checkable
          checkbox-position="left"
          :checked-rows.sync="checkedRows"
          class="pagination-table table__overflow-auto inputs-outputs-table px-3"
          :data="txsList"
          hoverable
          :loading="isTxDataLoading"
          @check="toggleTxData"
        >
          <template
            v-if="isTxDataLoading"
            slot="loading"
            slot-scope="props"
          >
            <gl-loader class="table__loader" />
          </template>
          <template
            slot="empty"
            slot-scope="props"
          >
            <div
              v-if="!isTxDataLoading"
              class="flex justify-center full grey-text mt-3 mb-4"
            >
              Data is empty
            </div>
          </template>
          <o-table-column
            v-slot="props"
            field="from"
            label="from"
          >
            <div
              v-if="props.row.from"
              class="link"
              @click="openInNewTabAddress(props.row.from)"
            >
              {{ trancateString(props.row.from, 8) }}
            </div>
            <div v-else>
              --
            </div>
          </o-table-column>
          <o-table-column
            v-slot="props"
            field="to"
            label="to"
          >
            <div
              v-if="props.row.to"
              class="link"
              @click="openInNewTabAddress(props.row.to)"
            >
              {{ trancateString(props.row.to, 8) }}
            </div>
            <div v-else>
              --
            </div>
          </o-table-column>
          <o-table-column
            v-slot="props"
            field="amount"
            label="amount"
          >
            <div
              class="flex capitalize ellipsis"
            >
              {{
                props.row.value || props.row.valueEth
                  ? toComaSeparate(formatBtcAmount(getDecimalVal(props.row, props.row.value || props.row.valueEth), true, 'eth', props.row.contractData && props.row.contractData.symbol))
                  : '--'
              }}
              <div
                v-if="props.row.valuePrice"
                class="fw-400 ml-2"
              >
                ({{ formatByPrice(props.row.valuePrice) }})
                <gl-icon
                  v-popover:tooltip.top="`${priceMessage('historical', props.row.valuePriceTimestamp)}`"
                  class="price-info-icon"
                  :height="11"
                  name="info"
                  :width="11"
                />
              </div>
            </div>
          </o-table-column>
        </o-table>
        <div
          v-if="txsList.length > 0 && txsList.length !== total"
          class="flex flex-end fullwidth pa-1"
        >
          <o-pagination
            v-if="txsList.length"
            class="stat-pagination"
            :current.sync="currentPage"
            order="right"
            :per-page="limit"
            simple
            :total="total"
            @change="pageChange"
          >
            <o-pagination-button
              slot="previous"
              slot-scope="props"
              :page="props.page"
            >
              <gl-menu-item
                class="change-page mr-2"
                :disabled="props.page.disabled"
                icon="left"
                :icon-height="24"
                :icon-width="24"
                label=""
                not-outline
              />
            </o-pagination-button>

            <o-pagination-button
              slot="next"
              slot-scope="props"
              :page="props.page"
            >
              <gl-menu-item
                class="change-page"
                :disabled="props.page.disabled"
                icon="right"
                :icon-height="24"
                :icon-width="24"
                label=""
                not-outline
              />
            </o-pagination-button>
          </o-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Vuex
import {mapActions, mapState} from 'vuex'
// Utils
import { featureAccess } from "@/utils/accesses";
import { formatDate } from "@/utils/format-date";
import { toComaSeparate } from '@/utils/formatNumber';
import { formatterAmountValue, calcSourcesRiskPercent, formatFunds } from '@/utils/report-data-formatter'
import { formatBtcAmount, getDecimalVal } from '@/utils/format-btc-amount';
import { formatShare, formatter } from "@/utils/sourcesFormatter";
import { sortingObjects, sourcesSortOrderArray } from "@/utils/sorting";
import {findColorByTypeScore, getNativeCoinIcon, tokensSorting} from "@/utils/cytoskape-ui-rules";
import { capitalizeFirstLetter, trancateString } from "@/utils/text-formatter";
import { formatByPrice, priceMessage } from "@/utils/format-by-price";
// Components
import GlLoader from "@/components/gl-loader";
import GlCheckbox from "@/components/gl-checkbox";
import GlMenuItem from '@/components/gl-menu-item';
import InfoBlock from '@/components/gl-info-block';
import StatusBlock from "@/pages/report/components/StatusBlock.vue";
import GlIcon from '@/components/gl-icon'
import vSelect from "vue-select";
import GlImg from "@/components/gl-img.vue";
//mixins
import deviceWidthMixin from '@/assets/mixins/deviceWidthMixin'
// Libs
import _ from "lodash";
import GlScoringBarChart from '@/components/charts/gl-scoring-bar-chart.vue'
import GlSelectButton from '@/components/gl-select-button.vue'
import uofModel from '@/pages/analytics/analytics-info-blocks/models/uofModel'
import appConfig from '@/utils/appConfig'
import PieDataList from '@/pages/report/components/PieDataList.vue'
import GlPieChart from '@/components/charts/gl-pie-chart.vue'

export default {
  name: 'TransactionInfo',
  components: {
    GlPieChart,
    PieDataList,
    GlSelectButton,
    GlScoringBarChart,
    vSelect,
    GlImg,
    StatusBlock,
    GlLoader,
    InfoBlock,
    GlCheckbox,
    GlMenuItem,
    GlIcon
  },
  mixins: [deviceWidthMixin],
  props: {
    selectedElement: {
      type: Object,
      default: () => ({})
    },
    searchMode: {
      type: String,
      default: 'tx',
    },
    cytoscape: {
      type: Object,
      default: () => ({})
    },
  },
  data() {
    return {
      showRiskMsg: false,
      addressRiskPercent: 0,
      addressUnknownSources: [],
      tokensList: [],
      simpleView: false,
      advancedView: false,
      trackByField: 'share',
      trackActivator: 'share',
      calcLoading: false,
      totalFunds: 0,
      sectionData: null,
      allDataSource: [],
      transactionData: {},
      addressData: {},
      transactionInfoData: {},
      transactionInfoDataIO: {},
      prevNextInfo: null,
      txsList: [],
      limit: 20,
      currentPage: 1,
      total: 1,
      checkedRows: [],
      outputsCheckedRows: [],
      isTxDataLoading: false,
      isTxInfoLoading: false,
      isAddressDataLoading: false,
      displayInputsTotal: [],
      displayOutputsTotal: [],
      activeTab: 'general',
      token: { address: '', symbol: 'ETH' },
      tabs: [{
        name: 'general',
        title: 'General',
        visible: true
      }, {
        name: 'sof',
        title: 'Exposure',
        visible: featureAccess('REPORT')
      }],
      uofData: uofModel(),
      activeExposurePart: { name: 'Source of Funds', value: 'incoming' },
      exposurePartOptions: [
        { name: 'Source of Funds', value: 'incoming' },
        { name: 'Use of Funds', value: 'outgoing' },
      ],
      activePartBar: { name: 'Type', value: 'funds.type', key: 'type' },
      partBarOptions: [
        { name: 'Type', value: 'funds.type', key: 'type' },
        { name: 'Entity', value: 'owner', key: 'owner' },
      ],
      step: 10
    }
  },
  computed: {
    appConfig() {
      return appConfig
    },
    ...mapState('analytics', ['coinType', 'coinData']),
    activeBarData() {
      return this.uofData[`${this.activeExposurePart.value}Data`][`${this.activePartBar.key}Grouping`];
    },
    addressAreUnidentified() {
      const sum = this.addressUnknownSources?.reduce(
        (acc, { share }) => acc + share,
        0,
      )

      return sum * 100 >= 75
    },
    txRiskPercentLabel() {
      if (this.activeExposurePart.value === 'outgoing') {
        return `${formatShare(this.addressRiskPercent)} in transaction are sent to risky destinations`
      }

      return `${formatShare(this.addressRiskPercent)} of funds in this transaction are from risky sources`
    },
  },
  watch: {
    'selectedElement.value': 'updateData',
  },
  mounted() {
    if (!this.token.address) {
      this.token.symbol = this.coinData.label
      this.token.icon = getNativeCoinIcon(this.coinData.key)
    }
    this.updateData()
  },
  methods: {
    calcSourcesRiskPercent,
    formatter,
    formatDate,
    formatFunds,
    formatShare,
    tokensSorting,
    getDecimalVal,
    featureAccess,
    toComaSeparate,
    trancateString,
    formatBtcAmount,
    findColorByTypeScore,
    capitalizeFirstLetter,
    formatByPrice,
    priceMessage,
    formatterAmountValue,
    changeActivePartBarValue(val) {
      this.activePartBar = val
      this.calkDataFormatter(
        this.uofData[`${this.activeExposurePart.value}Data`],
        this.activeExposurePart.value,
        this.activePartBar
      )
    },
    changeActiveExposure(val) {
      this.activeExposurePart = val
      this.calkDataFormatter(
        this.uofData[`${this.activeExposurePart.value}Data`],
        this.activeExposurePart.value,
        this.activePartBar
      )
    },
    toFullReport(val) {
      const { href } = this.$router.resolve({ name: 'report', query: { tx: val, type: this.coinType }})
      window.open(href, '_blank')
    },
    openInNewTabAddress(address) {
      const { href } = this.$router.resolve({ name: 'analytics', query: { address, type: this.coinType } })
      window.open(href, '_blank')
    },
    handleSelectToken() {
      if (this.activeTab === 'sof') {
        this.loadCalcData(false)
      }
    },
    toggleTxData(data) {
      const selectedEl = data[data.length - 1]
      const diffItem = _.difference(this.checkedRows, data)
      const isAdd = data.length > this.checkedRows.length

      if (isAdd) {
        this.cytoscape.visualizeEthData(this.selectedElement.data.txHash, [selectedEl], {}, this.prevNextInfo)
        this.cytoscape.checkFullTxsListOnGraph(this.selectedElement.data.txHash)
      } else if (diffItem.length > 0) {
        diffItem.forEach(ele => {
          this.cytoscape.removeTxByID(ele._id)
          this.cytoscape.checkFullTxsListOnGraph(this.selectedElement.data.txHash)
        })
      }

    },
    updateData() {
      this.uofData = uofModel()
      this.simpleView = !this.selectedElement.data.simpleView
      this.activeTab = 'general'
      this.advancedView = Boolean(this.selectedElement.data.viewAdditionalData)
      this.selectedElement.formattedTotalAmount = toComaSeparate(formatBtcAmount(this.selectedElement.data.amount, true, this.selectedElement.data.searchType, this.selectedElement.data.contract))
      this.loadTxsList()
    },
    ...mapActions('analytics', [
      'getTransactionInfo',
      'getTransactionData',
      'getAddressData',
      'getTxEthScore',
      'getTxEthData',
      'getTransactionTokens',
      'getTxUOF',
    ]),
    pageChange(page) {
      this.currentPage = page
      this.loadTxsList()
      this.checkedRows = []
    },
    loadTxsList() {
      this.isTxDataLoading = true
      this.getTxEthData({
        tx: this.selectedElement.data.txHash,
        limit: this.limit,
        page: this.currentPage,
        address: new RegExp(this.coinData?.addressRegex).test(this.cytoscape.search) && !new RegExp(this.coinData?.txRegex).test(this.cytoscape.search)
          ? this.cytoscape.search
          : undefined,
        only: 'list'
      }).then(({ data, success }) => {
        if (success) {
          this.txsList = data.txs

          this.getTransactionTokens({ tx: this.selectedElement.data.txHash }).then(({ data, success }) => {
            if (!success) this.calcLoading = false
            if (data.message) {
              this.$toasted.global.error({message: `${data.message}`})
            }
            const formattedTokensList = data.map(token => {
              return {
                ...token,
                name: token.symbol
              }
            })
            this.tokensList = this.tokensSorting(formattedTokensList)

            if (this.tokensSorting(formattedTokensList).length) {
              this.token = this.tokensSorting(formattedTokensList)[0]
            }

            // this.tokensList.unshift({ address: '', symbol: this.coinData.label, icon: getNativeCoinIcon(this.coinData.key) })
          }).catch(() => {
            this.calcLoading = false
          })

          this.prevNextInfo = data.prevNextInfo
          this.total = data.total
          this.preCheckingRows()
        }
      }).finally(() => {
        this.isTxDataLoading = false
      })
    },
    preCheckingRows() {
      this.checkedRows = this.txsList.filter(el => this.cytoscape.cy.edges().find(edge => edge.data("_id") === el._id))
    },
    loadCalcData(needSetAvailableToken = true) {
      this.calcLoading = true

      this.getTxUOF({ tx_hash: this.selectedElement.data.txHash,token: this.token.address, tokenTicker: this.token.symbol }).then(({ data, success}) => {
            if (success) {
              const { sof, uof }  = data

              if (!this.token.address && !sof?.data?.sources?.length && !uof?.data?.sources?.length && this.tokensList.length && needSetAvailableToken) {
                this.token = this.tokensSorting(this.tokensList)[0]
                this.loadCalcData(false)
              }

              this.calkDataFormatter(sof?.data || {}, 'incoming', this.activePartBar)
              this.calkDataFormatter(uof?.data || {}, 'outgoing', this.activePartBar)

              const sofTotalFunds = sof?.data?.totalFunds ?? null;
              const uofTotalFunds = uof?.data?.totalFunds ?? null;

              this.totalFunds = (sofTotalFunds === null && uofTotalFunds === null)
                ? null
                : (!appConfig.VUE_APP_MIN_MAX_TOTAL_FUNDS ? sofTotalFunds : Math.max(sofTotalFunds, uofTotalFunds));

            }
          }).catch(() => {
            this.calcLoading = false
          }).finally(() => {
            this.calcLoading = false
          })
    },
    calkDataFormatter({ sources = [], currencies = {} }, direction, partOptions) {
      this.uofData[`${direction}Data`].sources = sources
      this.uofData[`${direction}Data`].currencies = currencies
      this.uofData[`${direction}Data`][`${partOptions.key}Grouping`] = sortingObjects(formatter(sources, partOptions.value), sourcesSortOrderArray)
        .map((source) => {
          const localCurrency = currencies[source.currency]
            ? { ...currencies[source.currency], decimals: currencies[source.currency].decimals || 0 }
            : {}

          return {
            ...source,
            owners: formatter(sources, 'owner')
              .filter((v) => v.funds.type === source.funds.type)
              .map((v) => ({
                ...v,
                name: v.owner,
                formattedAmount: this.formatBtcAmount(v.amount / Math.pow(10, localCurrency.decimals ?? this.coinData.decimals ?? 0), true, this.coinData.key, localCurrency.currency || this.coinData.label),
              })),
            funds: {
              ...source.funds,
              default: source.funds.default || false
            },
            key: source.funds.type,
            name: `${this.trackByField === 'share' ? formatShare(source.share) : source.formattedAmount} ${source.funds.type}`,
            tooltip: `${source.funds.type} ${formatShare(source.share)}`,
            pieValue: source.share,
            value: source.share,
            itemStyle: {color: source.funds.default ? this.findColorByTypeScore(-1) : this.findColorByTypeScore(source.funds.score)},
            currencyData: localCurrency,
            formattedAmount: this.toComaSeparate(String(this.formatterAmountValue(source.amount, localCurrency?.decimals ?? this.coinData.decimals, localCurrency?.currency || this.coinData.label))),
          }
        })

      const opIndex = this.exposurePartOptions.findIndex(({ value }) => value === direction)

      if (opIndex !== -1) {
        this.$set(this.exposurePartOptions[opIndex], 'disabled', Boolean(!sources.length))
      }

      const maxValue = this.uofData[`${direction}Data`][`${partOptions.key}Grouping`].reduce(
        (max, obj) => (obj.share > max ? obj.share : max),
        this.uofData[`${direction}Data`][`${partOptions.key}Grouping`][0]?.share,
      )

      this.uofData[`${direction}Data`].maxShare = Math.ceil((maxValue * 100) / this.step) * this.step

      if (direction === this.activeExposurePart.value) {
        this.addressUnknownSources = this.uofData[`${direction}Data`].sources.filter(source => source.listType === 'Unknown sources')

        this.addressRiskPercent = 0

        this.addressRiskPercent = this.calcSourcesRiskPercent(this.uofData[`${direction}Data`][`${partOptions.key}Grouping`])
      }
    },
    handlePieHover($event) {
      const a = document.getElementById('sourcePie')

      if (a) {
        a.style.left = `${$event.event.event.clientX + 10}px`
        a.style.top = `${$event.event.event.clientY + 10}px`
      }

      if ($event.event.event.clientX) {
        this.sectionData = $event.data || null
      }
    },
    trackBy() {
      if (this.trackByField === 'share') {
        this.trackByField = 'amount'
      } else {
        this.trackByField = 'share'
      }

      this.uofData.incomingData.typeGrouping = this.uofData.incomingData.typeGrouping.map(item => ({
        ...item,
        name: `${this.trackByField === 'share' ? formatShare(item.share) : item.formattedAmount} ${item.funds.type}`,
        tooltip: `${item.funds.type} ${this.trackByField === 'share' ? formatShare(item.share) : item.formattedAmount}`,
      }))
    },
    changeTab(tab) {
      this.activeTab = tab
      switch (tab) {
        case 'sof':
          this.loadCalcData();
          break;
        case 'general':
          this.updateData()
          break;
      }
      this.$nextTick(() => {
        document.querySelector('.analytics-tabs').scrollLeft = document.querySelector('.analytics-tabs-item--active').offsetLeft - 10
      });
    },
    handleSimpleView() {
      this.cytoscape.toggleSimpleView(!this.simpleView, this.selectedElement.data.txHash)
    },
    handleFullView() {
      this.cytoscape.toggleFullView(this.advancedView, this.selectedElement.data.txHash)
    },
    async copy(value) {
      await navigator.clipboard.writeText(value)
        .then(() => {
          this.$toasted.global.success({ message: 'Copied!' })
        })
        .catch((err) => {
          console.log(err)
        })
    },
  },
}
</script>

<style scoped>
.source__action-block {
  position: fixed;
  bottom: 0;
  width: 640px;
  box-shadow: 0 0 8px 0 rgba(211, 211, 211, 0.64);
  padding: 12px 24px 24px;
  background: var(--white);
}

.sidebar-container {
  flex: 1;
  overflow-y: auto;
}
.sidebar-title {
  margin-bottom: 25px;
}
.stat {
  display: flex;
  flex-wrap: wrap;
}
.stat-item {
  width: 25%;
  word-break: break-word;
  margin-bottom: 20px;
}
.full {
  width: 100%;
}
.key {
  font-size: 12px;
  font-weight: 500;
  color: var(--light-grey-blue);
  text-transform: uppercase;
  margin-bottom: 6px;
}
.stat-tag {
  display: flex;
  flex-wrap: wrap;
}

.risk-score-value {
  text-align: center;
  font-size: 40px;
  font-weight: bold;
}

.risk-score-wrap {
  position: absolute;
  top: calc(50% - 80px);
}

.risk-score-label {
  font-size: 14px;
}

.testPie {
  /*min-width: 400px;*/
  width: max-content;
  position: fixed;
  padding: 16px;
  z-index: 10;
  background-color: var(--white);
  box-shadow: 4px 5px 8px 0 rgba(211, 211, 211, 0.64);
  border-radius: 3px;
  /*border: solid 1px var(--soft-blue);*/
  transform: translateX(-100%);
}

.table__loader {
  position: absolute;
  top: calc(50% + 40px);
  left: 50%;

  transform: translate(-50%, -50%);
}

@media (max-width: 767px) {
  .source__action-block {
    position: static;
    width: 100%;
  }
  .stat-item {
    width: 50%;
  }
}
@media (max-width: 360px) {
  .stat-item {
    width: 100%;
  }
}
</style>
