<template>
  <div class="fs-14">
    <h4 class="sidebar__sub-title mb-3 px-3 stat">
      General
    </h4>
    <div class="stat px-3">
      <div class="stat-tag full pp-cluster-info">
        <div class="stat-item relative pp-cluster-info__item pp-cluster-info__item--to-labeling">
          <InfoBlock
            label="Cluster id"
            :value="selectedElement.value"
          />
          <gl-menu-item
            v-if="isAdmin"
            v-popover:tooltip.top="'Edit labels'"
            class="sidebar__to-labeling"
            icon="edit-block-active"
            :icon-height="24"
            :icon-width="24"
            @click="toLabeling(selectedElement.value)"
          />
        </div>
        <InfoBlock
          label="Cluster size"
          :loading="isAddressDataLoading"
          :value="addressData
            && addressData.clusterData
            && addressData.clusterData.clusterSize
            && toComaSeparate(addressData.clusterData.clusterSize)"
        />
        <div class="sidebar__section pp-cluster-info__item">
          <p class="sidebar__analytics-label">
            Description
          </p>
          <p
            class="sidebar__analytics-description flex align-center value"
            :title="selectedElement.value"
          >
            {{ addressData && addressData.clusterData && addressData.clusterData.description || '--' }}
          </p>
        </div>
        <InfoBlock
          label="owner"
          :link-text-value="Boolean(selectedElement.data.clusterData.entityId)"
          :value="(selectedElement
            && selectedElement.data
            && selectedElement.data.clusterData
            && selectedElement.data.clusterData.owner) || 'Not identified'"
          @clickedValue="goToEntity(selectedElement.data.clusterData.entityId)"
        />
        <div class="stat-item sidebar__section sidebar__section--full pp-cluster-info__item">
          <div class="key">
            Type
          </div>
          <div class="value">
            <VueSkeletonLoader
              v-if="isAddressDataLoading"
              animation="wave"
              :color="'#bec3d8'"
              :height="20"
              :radius="'2'"
              type="rect"
            />
            <div v-else>
              <GlTag
                v-if="addressData
                  && addressData.clusterData
                  && addressData.clusterData.type
                  && addressData.clusterData.type.name
                  && String(addressData.clusterData.type.name)"
                class="mr-1 mb-1"
                :score="addressData.clusterData.type.score"
                :tag="addressData.clusterData.type.name"
                :value="addressData.type && addressData.clusterData.type.name"
              />
              <span v-else>--</span>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-3">
        <p class="sidebar__analytics-label">
          Tags
        </p>
        <VueSkeletonLoader
          v-if="isAddressDataLoading"
          animation="wave"
          :color="'#bec3d8'"
          :height="20"
          :radius="'2'"
          type="rect"
        />
        <div v-else>
          <div
            v-if="!addressData.clusterData || !addressData.clusterData.tags || addressData.clusterData.tags.length === 0"
            class="bold"
          >
            --
          </div>
          <div v-else>
            <GlTag
              v-for="(tag, index) in addressData.clusterData.tags"
              :key="index"
              class="mr-1 mb-1"
              :score="tag.score"
              :tag="tag.name"
            />
          </div>
        </div>
      </div>
    </div>
    <ClusterAddressesTable
      v-if="selectedElement.data.cluster && String(selectedElement.data.cluster)
        || selectedElement.data.clusterData && selectedElement.data.clusterData.cluster"
      :cluster="selectedElement.data.cluster && String(selectedElement.data.cluster)
        || selectedElement.data.clusterData && selectedElement.data.clusterData.cluster"
      @add-tx-data="$emit('add-tx-data', $event)"
    />
  </div>
</template>

<script>
// Utils
import { toComaSeparate } from '@/utils/formatNumber'
import { goToEntity } from "@/utils/go-to-route";
// Vuex
import { mapActions, mapGetters } from "vuex";
// Components
import VueSkeletonLoader from 'skeleton-loader-vue';
import InfoBlock from '@/components/gl-info-block'
import GlIcon from '@/components/gl-icon'
import GlMenuItem from '@/components/gl-menu-item'
import GlTag from '@/components/gl-tag'
import ClusterAddressesTable from './components/clusterAddressesTable'

export default {
  name: 'ClusterInfo',
  components: { InfoBlock, GlIcon,GlMenuItem, VueSkeletonLoader, GlTag, ClusterAddressesTable  },
  props: {
    selectedElement: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      addressData: {},
      isAddressDataLoading: false,
    }
  },
  computed: {
    ...mapGetters('user', ['isAdmin']),
    elementLabel() {
      return (this.selectedElement?.type || '').replace('tx', 'Transaction Hash')
    },
  },
  watch: {
    'selectedElement.value': 'updateData',
  },
  mounted() {
    this.updateData();
  },
  methods: {
    toComaSeparate,
    goToEntity,
    ...mapActions('analytics', ['getAddressData', 'getTransactionData', 'getTxStats']),
    ...mapActions('entity', ['getEntityList']),
    async updateData() {
      if (this.selectedElement.data.address) {
        await this.loadAddressData(this.selectedElement.data.address)
      } else {
        await this.setDefaultData()
      }
    },
    async gettingEntityByOwner(owner) {
      if (owner) {
        this.isAddressDataLoading = true
        await this.getEntityList({ name: owner }).then((data) => {
          if (data?.items?.find(el => el?.name?.value?.toLowerCase() === owner?.toLowerCase())) {
            this.$nextTick(() => {
              this.addressData.entityId = data?.items?.find(el => el?.name?.value.toLowerCase() === owner.toLowerCase()).id || null
              this.selectedElement.data.clusterData.entityId = data?.items?.find(el => el?.name?.value?.toLowerCase() === owner?.toLowerCase()).id || null
              this.$set(this.addressData, 'entityId', data?.items?.find(el => el?.name?.value?.toLowerCase() === owner?.toLowerCase()).id)
            })
          }

          return null
        }).finally(() => {
          this.isAddressDataLoading = false
        })
      }

      return null
    },
    setDefaultData() {
      this.addressData = {}
    },
    toLabeling(val) {
      this.$router.push({ name: 'labeling', query: { cluster: val, tab: 'labeling' } })
    },
    loadAddressData(address) {
      this.isAddressDataLoading = true
      this.getAddressData({ address })
          .then(({ data }) => {
            this.addressData = data;
          })
          .finally(() => {
            if (this.selectedElement.data.clusterData.owner || this.addressData.clusterData.owner) {
              setTimeout(() => {this.gettingEntityByOwner(this.selectedElement.data.clusterData.owner)}, 50)
            }
            this.isAddressDataLoading = false
          })
    },
  },
}
</script>

<style scoped>
.sidebar-container {
  flex: 1;
  overflow-y: auto;
}
.sidebar-title {
  margin-bottom: 25px;
}
.stat {
  display: flex;
  flex-wrap: wrap;
}
.stat-item {
  width: 50%;
  word-break: break-word;
  margin-bottom: 20px;
}
.value {
  font-weight: 500;
}
.full {
  width: 100%;
}
.key {
  font-size: 12px;
  font-weight: 500;
  color: var(--light-grey-blue);
  text-transform: uppercase;
  margin-bottom: 6px;
}
.stat-tag {
  display: flex;
  flex-wrap: wrap;
}

.pp-cluster-info .all-types__info-block {
  width: 50% !important;
}
.pp-cluster-info .key {
  padding-top: 8px;
}
.pp-cluster-info__item--to-labeling {
  display: flex;
  align-items: flex-end;
}
.pp-cluster-info__item--to-labeling .all-types__info-block {
  flex: 0 1 max-content;
}
.pp-cluster-info__item--to-labeling .sidebar__to-labeling {
  flex: 1 0 auto;
  padding-bottom: 16px;
}
</style>
