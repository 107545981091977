var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fs-14"},[_c('h4',{staticClass:"sidebar__sub-title mb-3 px-3 stat"},[_vm._v(" General ")]),_c('div',{staticClass:"stat px-3"},[_c('div',{staticClass:"stat-tag full pp-cluster-info"},[_c('div',{staticClass:"stat-item relative pp-cluster-info__item pp-cluster-info__item--to-labeling"},[_c('InfoBlock',{attrs:{"label":"Cluster id","value":_vm.selectedElement.value}}),(_vm.isAdmin)?_c('gl-menu-item',{directives:[{name:"popover",rawName:"v-popover:tooltip.top",value:('Edit labels'),expression:"'Edit labels'",arg:"tooltip",modifiers:{"top":true}}],staticClass:"sidebar__to-labeling",attrs:{"icon":"edit-block-active","icon-height":24,"icon-width":24},on:{"click":function($event){return _vm.toLabeling(_vm.selectedElement.value)}}}):_vm._e()],1),_c('InfoBlock',{attrs:{"label":"Cluster size","loading":_vm.isAddressDataLoading,"value":_vm.addressData
          && _vm.addressData.clusterData
          && _vm.addressData.clusterData.clusterSize
          && _vm.toComaSeparate(_vm.addressData.clusterData.clusterSize)}}),_c('div',{staticClass:"sidebar__section pp-cluster-info__item"},[_c('p',{staticClass:"sidebar__analytics-label"},[_vm._v(" Description ")]),_c('p',{staticClass:"sidebar__analytics-description flex align-center value",attrs:{"title":_vm.selectedElement.value}},[_vm._v(" "+_vm._s(_vm.addressData && _vm.addressData.clusterData && _vm.addressData.clusterData.description || '--')+" ")])]),_c('InfoBlock',{attrs:{"label":"owner","link-text-value":Boolean(_vm.selectedElement.data.clusterData.entityId),"value":(_vm.selectedElement
          && _vm.selectedElement.data
          && _vm.selectedElement.data.clusterData
          && _vm.selectedElement.data.clusterData.owner) || 'Not identified'},on:{"clickedValue":function($event){return _vm.goToEntity(_vm.selectedElement.data.clusterData.entityId)}}}),_c('div',{staticClass:"stat-item sidebar__section sidebar__section--full pp-cluster-info__item"},[_c('div',{staticClass:"key"},[_vm._v(" Type ")]),_c('div',{staticClass:"value"},[(_vm.isAddressDataLoading)?_c('VueSkeletonLoader',{attrs:{"animation":"wave","color":'#bec3d8',"height":20,"radius":'2',"type":"rect"}}):_c('div',[(_vm.addressData
                && _vm.addressData.clusterData
                && _vm.addressData.clusterData.type
                && _vm.addressData.clusterData.type.name
                && String(_vm.addressData.clusterData.type.name))?_c('GlTag',{staticClass:"mr-1 mb-1",attrs:{"score":_vm.addressData.clusterData.type.score,"tag":_vm.addressData.clusterData.type.name,"value":_vm.addressData.type && _vm.addressData.clusterData.type.name}}):_c('span',[_vm._v("--")])],1)],1)])],1),_c('div',{staticClass:"mb-3"},[_c('p',{staticClass:"sidebar__analytics-label"},[_vm._v(" Tags ")]),(_vm.isAddressDataLoading)?_c('VueSkeletonLoader',{attrs:{"animation":"wave","color":'#bec3d8',"height":20,"radius":'2',"type":"rect"}}):_c('div',[(!_vm.addressData.clusterData || !_vm.addressData.clusterData.tags || _vm.addressData.clusterData.tags.length === 0)?_c('div',{staticClass:"bold"},[_vm._v(" -- ")]):_c('div',_vm._l((_vm.addressData.clusterData.tags),function(tag,index){return _c('GlTag',{key:index,staticClass:"mr-1 mb-1",attrs:{"score":tag.score,"tag":tag.name}})}),1)])],1)]),(_vm.selectedElement.data.cluster && String(_vm.selectedElement.data.cluster)
      || _vm.selectedElement.data.clusterData && _vm.selectedElement.data.clusterData.cluster)?_c('ClusterAddressesTable',{attrs:{"cluster":_vm.selectedElement.data.cluster && String(_vm.selectedElement.data.cluster)
      || _vm.selectedElement.data.clusterData && _vm.selectedElement.data.clusterData.cluster},on:{"add-tx-data":function($event){return _vm.$emit('add-tx-data', $event)}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }