<template>
  <div class="menu-dropdown-items-wrapper menu-dropdown__view">
    <div
      v-if="!$can('use', 'eth')"
      class="label mb-2"
    >
      View mode
    </div>
    <gl-radio
      v-if="!$can('use', 'eth')"
      v-model="localViewMode"
      label="Address view"
      value="address"
      @change="$emit('change-view-mode', $event)"
    />
    <gl-radio
      v-if="!$can('use', 'eth')"
      v-model="localViewMode"
      label="Cluster view"
      value="cluster"
      @change="$emit('change-view-mode', $event)"
    />
    <hr v-if="!$can('use', 'eth')">
    <div class="label mb-2">
      Address labels
    </div>
    <gl-checkbox
      v-if="$can('use', 'btc')"
      v-model="localLabel"
      label="Cluster / Owner"
      @input="$emit('change-node-labels', $event)"
    />
    <gl-checkbox
      v-if="$can('use', 'eth')"
      v-model="localLabel"
      label="Owner"
      @input="$emit('change-node-labels', $event)"
    />
    <gl-checkbox
      v-model="localAddress"
      label="Address"
      @input="$emit('change-node-labels', $event)"
    />
    <hr>
    <div class="label mb-2">
      Transaction labels
    </div>
    <gl-checkbox
      v-model="localAmount"
      label="Amounts"
      @input="$emit('change-edge-labels', $event)"
    />
    <gl-checkbox
      v-model="localHash"
      label="Hash"
      @input="$emit('change-edge-labels', $event)"
    />
    <hr v-if="localAmount">
    <div
      v-if="localAmount"
      class="label mb-2"
    >
      Amounts
    </div>
    <gl-radio
      v-if="localAmount"
      v-model="localAmountViewMode"
      label="Tokens"
      value="tokens"
      @change="$emit('change-amount-view-mode', $event)"
    />
    <gl-radio
      v-if="localAmount"
      v-model="localAmountViewMode"
      :label="`Fiat ${fiat.label}`"
      value="fiat"
      @change="$emit('change-amount-view-mode', $event)"
    />
  </div>
</template>

<script>
// Components
import GlCheckbox from '@/components/gl-checkbox'
import GlRadio from '@/components/gl-radio-button'
// Utils
import { findFiatByKey } from "@/utils/format-by-price";
// Vuex
import { mapState } from 'vuex'

export default {
  components: {
    GlCheckbox,
    GlRadio,
  },
  props: {
    viewMode: {
      type: String,
      default: 'address',
    },
    amountViewMode: {
      type: String,
      default: 'tokens',
    },
    label: {
      type: Boolean,
      default: true,
    },
    address: {
      type: Boolean,
      default: false,
    },
    amount: {
      type: Boolean,
      default: false,
    },
    hash: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fiat: '',
    }
  },
    computed: {
    ...mapState('user', ['userData', 'fiatList']),
    localViewMode: {
      get: function () {
        return this.viewMode
      },
      set: function (e) {
        this.$emit('view-mode', e)
      },
    },
    localAmountViewMode: {
      get: function () {
        return this.amountViewMode
      },
      set: function (e) {
        this.$emit('amount-view-mode', e)
      },
    },
    localLabel: {
      get: function () {
        return this.label
      },
      set: function (e) {
        this.$emit('label', e)
      },
    },
    localAddress: {
      get: function () {
        return this.address
      },
      set: function (e) {
        this.$emit('address', e)
      },
    },
    localAmount: {
      get: function () {
        return this.amount
      },
      set: function (e) {
        this.$emit('amount', e)
      },
    },
    localHash: {
      get: function () {
        return this.hash
      },
      set: function (e) {
        this.$emit('hash', e)
      },
    },
  },
  mounted() {
    this.fiat = findFiatByKey(
      this.userData.preferredCurrency || this.fiatList[0].key,
    )
  },
 
}
</script>

<style>
.menu-dropdown__view .label {
  margin-bottom: 9px !important;
  padding-top: 1px;
}
.menu-dropdown__view .gl-checkbox,
.menu-dropdown__view .gl-radio {
  line-height: 17px;
}
.menu-dropdown__view .gl-checkbox__check,
.menu-dropdown__view .gl-radio__check {
  margin-left: -22px;
}
.menu-dropdown-items-wrapper.menu-dropdown__view label + label {
  margin-top: 12px;
}
.menu-dropdown-items-wrapper.menu-dropdown__view hr {
  margin: 15px 0;
}
</style>