<template>
  <div class="gl-select-button-wrapper">
    <div
      v-for="(item, i) in options"
      :key="i"
      class="gl-select-button__item"
      :class="[
        { 'gl-select-button__item--active': item.value === value.value },
        { 'gl-select-button__disabled': disabled },
        { 'gl-select-button__item--disabled': item.disabled },
      ]"
      @click="itemClick(item)"
    >
      {{ item.name }}
    </div>
  </div>
</template>

<script>

export default {
  props: {
    value: {
      type: [String, Object],
      default: null,
    },
    options: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    itemClick(item) {
      if (item.disabled || this.disabled) return
      this.$emit('input', item)
    },
  },
}
</script>

<style>
.gl-select-button-wrapper {
  display: flex;
  width: fit-content;
  background-color: var(--pale-blue-transparent);
  border-radius: 8px;
  height: 40px;
  flex-shrink: 0;
  padding: 4px;
}

.gl-select-button__item {
  display: inline-flex;
  padding: 8px 29px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  color: var(--accent-reflex-blue, #5A78EA);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 16px */
}

.gl-select-button__item--active {
  color: var(--white);
  background-color: var(--accent-reflex-blue, #5A78EA);
}

.gl-select-button__disabled {
  cursor: not-allowed;
  opacity: 0.35;
}

.gl-select-button__item--disabled {
  cursor: not-allowed;
  opacity: 0.30;
}

@media (max-width: 767px) {
  .gl-select-button-wrapper {
    width: 100%;
  }

  .gl-select-button__item {
    width: inherit;
    font-size: 14px;
  }
}
</style>
